





















import { useUser } from '~/modules/customer/composables/useUser';
import {
  ref,
  computed,
  defineComponent,
  PropType,
  toRef,
  useFetch,
  onMounted,
  useContext
} from '@nuxtjs/composition-api';
import { Product } from '~/modules/catalog/product/types';
import userGetters from '~/modules/customer/getters/userGetters';
import useNotify from '~/modules/czar/productalert/priceNotify';
import { useUiNotification } from '~/composables';

export default defineComponent({
  name: 'PriceAlert',
  props: {
    product: {
      type: [Object, null] as PropType<Product>,
      default: null,
    },
    isFetching: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    /* const { isAuthenticated, user, register, login, loading, load: loadUser } = useUser();
    const userEmail = computed(() => userGetters.getEmailAddress(user.value)); */
    const { app: { i18n } } = useContext();
    const { isAuthenticated } = useUser();
    const product = toRef(props, 'product');
    const { usePriceNotify } = useNotify();
    const notify_pop = ref('no');
    const userEmailGuest = ref('');
    const error_msg = ref('');
    const mp_customer_email = ref('');
    const { send: sendNotification } = useUiNotification();

    const NotifyPopupPrice = async () => {
      if (typeof window !== 'undefined') {
        mp_customer_email.value = localStorage.getItem('mp_customer_email');
        if (isAuthenticated.value && mp_customer_email) {
          var queryVariables = {
            email: mp_customer_email.value,
            productSku: product.value.sku
          }
          await usePriceNotify(queryVariables).then(response => {
            if (response.priceNotify.value?.MpProductAlertNotifyPriceDrops != null) {
              var message = "Thanks! We'll send you an email once this product's price changes.";
              var type = 'success';
              var icon = 'check';
              priceNotification(message, type, icon);
            } else {
              var message = "You have already subscribed this product.";
              var type = 'danger';
              var icon = 'cross';
              priceNotification(message, type, icon);
            }
          }).catch((error) => {
            console.log(error);
          }).finally(() => {
            /* priceNotification(); */
          });
          notify_pop.value = 'no';
        } else {
          notify_pop.value = 'yes';
        };
      };

      /* if (isAuthenticated.value) {
          var queryVariables = {
          email: userEmail.value,
          productSku: product.value.sku
          }
          const usePriceNotifyResult = usePriceNotify(queryVariables);
          notify_pop.value = 'no';
      } else {
          notify_pop.value = 'yes';
      }; */
    };

    const priceNotification = (message, type, icon) => {
      sendNotification({
        id: Symbol('user_updated'),
        message: i18n.t(message) as string,
        type: type,
        icon: icon,
        persist: false,
      });
    };

    const notify_me_guest = async () => {
      if (userEmailGuest.value) {
        error_msg.value = '';
        var queryVariables = {
          email: userEmailGuest.value,
          productSku: props.product.sku
        };
        await usePriceNotify(queryVariables).then(response => {
          if (response.priceNotify.value?.MpProductAlertNotifyPriceDrops != null) {
            var message = "Thanks! We'll send you an email once this product's price changes.";
            var type = 'success';
            var icon = 'check';
            priceNotification(message, type, icon);
          } else {
            var message = "You have already subscribed this product.";
            var type = 'danger';
            var icon = 'cross';
            priceNotification(message, type, icon);
          }
        }).catch((error) => {
          console.log(error);
        }).finally(() => {
          
        });
        notify_pop.value = 'no';
      } else {
        error_msg.value = '*This is a required field.';
      }
    }

    const close_popup_modal = async () => {
      notify_pop.value = 'no';
    };

    return {
      NotifyPopupPrice,
      /* userEmail, */
      notify_pop,
      close_popup_modal,
      notify_me_guest,
      userEmailGuest,
      error_msg,
    }
  }
});
