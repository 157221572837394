import { ref } from '@nuxtjs/composition-api';
import stockNotifyGql from './query/stockNotify.gql';
import { useApi } from '~/composables/useApi';

type stockNotifyResponse = {
    customer_email: '',
};

export function useStockNotifyqry(){
    const { query } = useApi();    
    const useStockNotify = async (queryVariables) => {
        const stockNotify = ref<any | null>(null);
        await query<stockNotifyResponse>(stockNotifyGql,queryVariables)
            .then((response) => {
                stockNotify.value = response?.data;
            })
            .catch(() => {
                
            });
        return {
        stockNotify,
        };
    };
    return {useStockNotify}
 }
export default useStockNotifyqry;