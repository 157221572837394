






































import { defineComponent, computed } from '@nuxtjs/composition-api';
import { SfButton } from '@storefront-ui/vue';
import { useUiState } from '~/composables/useUiState';
import SvgImage from '~/components/General/SvgImage.vue';

export default defineComponent({
  name: 'AddToWishlist',
  components: {
    SfButton,
    SvgImage,
  },
  props: {
    component: {
      type: String,
      default: 'span',
    },
    isInWishlist: {
      type: Boolean,
      default: false,
    },
    wishlistIcon: {
      type: String,
      default: 'heart',
    },
    isInWishlistIcon: {
      type: String,
      default: 'heart_fill',
    },
    isShow: {
      type: Boolean,
      default: false,
    },
    category_view_type: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { toggleLoginModal } = useUiState();
    const actionText = computed(() => (props.isInWishlist ? 'Remove from Wishlist' : 'Add to Wishlist'));
    const openloginpopup = () => {
      toggleLoginModal();
    };

    return {
      actionText,
      openloginpopup
    };
  },
});
