



















import { defineComponent, computed } from '@nuxtjs/composition-api';
import { SfBanner, SfLink, SfImage, } from '@storefront-ui/vue';

export default defineComponent({
    components: {

    },
    props: {
        manufacturer_name: {},
        sku : {},
        manufacturer_part_no : {},
    },
    setup(props) {

        setTimeout(function () {
            if (process.client) {
                function saLoadScript(src) { 
                    var js = window.document.createElement('script'); 
                    js.src = src; 
                    js.type = 'text/javascript'; 
                    document.getElementsByTagName("head")[0].appendChild(js); 
                } 
                saLoadScript("https://www.shopperapproved.com/widgets/group2.0/31975.js");
            }
        }, 1000);
        return {

        };
    },
});
