<template>
  <div :class="flowType == 'CART' ? 'syf-product-widget-cart' : 'syf-product-widget'">
    <div class="syf-product-price-view">
      <div id="product-content" style="display: none">{{ $fc(price) }}</div>
      <div class="sync-price"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SynchronyAd',
  props: {
    price: {
      type: [Number, String],
      required: true
    },
    flowType: {
      type: String,
      default: 'PDP'
    }
  },
  mounted() {
    this.initWidget();
  },
  watch: {
    '$route.fullPath'() {
      this.$nextTick(() => {
        this.initWidget();
      });
    },
    price(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$nextTick(() => {
          this.initWidget();
        });
      }
    }
  },
  methods: {
    initWidget() {
      // Update config
      window.syfWidgetObject = {
        syfPartnerId: 'PI2001420501',
        flowType: this.flowType
      };

      if (this.price) {
        const waitForContent = setInterval(() => {
          if (document.querySelector('#product-content')) {
            clearInterval(waitForContent);
            this.loadUniFiScript();
          }
        }, 500);
      }
    },

    loadUniFiScript() {
      const existingScript = document.getElementById('syfMPPScript');
      if (existingScript && window.UniFi?.renderWidgets) {
        window.UniFi.renderWidgets();
        return;
      }

      // If script not loaded yet
      const script = document.createElement('script');
      script.id = 'syfMPPScript';
      script.src = 'https://pdpone.syfpayments.com/mpp/UniFi.js';
      script.async = true;
      script.onload = () => {
        if (window.UniFi?.renderWidgets) {
          window.UniFi.renderWidgets();
        }
      };
      document.body.appendChild(script);
    }
  }
};
</script>

<style lang="scss" scoped>
.syf-product-widget {
  max-width: 496px;
  margin-top: 20px;
  margin-bottom: -10px;
  position: relative;
}

.syf-product-widget-cart {
  margin: 10px 0;
  min-height: 37px;
  position: relative;
}

.syf-product-widget-cart .sync-price:empty::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background: linear-gradient(90deg, rgba(181, 181, 181, 0) 0%, rgb(234, 234, 234) 40%, rgb(225, 225, 225) 60%, rgba(212, 212, 212, 0) 100%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite linear;
  z-index: 1;
}

/* Shimmer animation */
@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}
</style>