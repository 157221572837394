











import { defineComponent, computed } from '@nuxtjs/composition-api';

export default defineComponent({
    components: {

    },
    props: {
        rebate: {},
    },
    setup(props) {


        return {

        };
    },
});
