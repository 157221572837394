



























import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
    components: {

    },
    props: {
        stock_display_status : {},
        stock_status: {},
        page_type: {
            type: String,
            default: '',
        }
    },
    setup(props) {
        const string_to_json = (message) => {
            const obj = JSON.parse(message);
            return obj;
        };

        return {
            string_to_json
        };
    },
});
