

















































































































import LazyHydrate from 'vue-lazy-hydration';
import {
  SfAddToCart,
  SfButton,
  SfGallery,
  SfHeading,
  SfLoader,
  SfPrice,
  SfRating,
  SfAlert,
  SfLink
} from '@storefront-ui/vue';
import {
  ref,
  computed,
  defineComponent,
  PropType,
  toRef,
  useFetch,
  onMounted,
  useContext,
} from '@nuxtjs/composition-api';
import {
  getName as getProductName,
  getPrice as getProductPrice,
  getSlug as getProductSlug,
  getId as getProductId,
  getCategory as getProductCategory,
  getProductSku,
  getProductThumbnailImage
} from '~/modules/catalog/product/getters/productGetters';
import {
  getTotalReviews,
  getAverageRating,
} from '~/modules/review/getters/reviewGetters';
import useWishlist from '~/modules/wishlist/composables/useWishlist';
/* import useComparelist from '~/modules/comparelist/composables/useComparelist'; */
import SvgImage from '~/components/General/SvgImage.vue';
import HTMLContent from '~/components/HTMLContent.vue';
import AddToWishlist from '~/components/AddToWishlist.vue';
/* import AddToCompare from '~/components/AddToCompare.vue'; */
import { useUser } from '~/modules/customer/composables/useUser';
import { Product } from '~/modules/catalog/product/types';
import { useCart } from '~/modules/checkout/composables/useCart';
import ProductTabs from '~/modules/catalog/product/components/tabs/ProductTabs.vue';
import { useProductGallery } from '~/modules/catalog/product/composables/useProductGallery';
import { TabsConfig, useProductTabs } from '~/modules/catalog/product/composables/useProductTabs';
import userGetters from '~/modules/customer/getters/userGetters';
import PriceAlert from '~/components/czar/productAlret/PriceAlert.vue';
import StockAlert from '~/components/czar/productAlret/StockAlert.vue';
import ProductInfo from '~/components/ProductDetailPage/ProductInfo.vue';
import ProductStatusInfo from '~/components/ProductDetailPage/ProductStatusInfo.vue';
import RebatePriceBox from '~/components/ProductDetailPage/RebatePriceBox.vue';
import LoadWhenVisible from '~/components/utils/LoadWhenVisible.vue';
import SkeletonLoader from '~/components/SkeletonLoader/index.vue';
import SynchronyAd from '~/components/SynchronyAd.vue';

export default defineComponent({
  name: 'SimpleProduct',
  components: {
    HTMLContent,
    LazyHydrate,
    SfAddToCart,
    SfButton,
    SfGallery,
    SfHeading,
    SfLoader,
    SfPrice,
    SfLink,
    SfRating,
    SfAlert,
    AddToWishlist,
    /* AddToCompare, */
    SvgImage,
    ProductTabs,
    PriceAlert,
    StockAlert,
    ProductInfo,
    ProductStatusInfo,
    RebatePriceBox,
    LoadWhenVisible,
    RelatedProducts: () => import('~/modules/catalog/product/components/RelatedProducts.vue'),
    UpsellProducts: () => import('~/modules/catalog/product/components/UpsellProducts.vue'),
    SkeletonLoader,
    SynchronyAd
  },
  transition: 'fade',
  props: {
    product: {
      type: [Object, null] as PropType<Product>,
      default: null,
    },
    isFetching: {
      type: Boolean,
      default: true,
    },
    routerDataRes: {
      type: Object,
      default: {}
    },
  },

  setup(props) {
    const context = useContext();
    // @ts-ignore
    const { imageProvider, magentoBaseUrl } = context.$vsf.$magento.config;
    const { isAuthenticated, user, register, login, loading, load: loadUser } = useUser();
    useFetch(async () => { if (user.value === null) { await loadUser(); } });
    const qty = ref(1);
    const productGallery_palceholder = ref([]);
    const product = toRef(props, 'product');
    const { addItem, error: cartError, loading: isCartLoading, canAddToCart, addedtocart_id: addedtocart_id } = useCart();
    const { productGallery, imageSizes } = useProductGallery(product);
    const { addOrRemoveItem, isInWishlist } = useWishlist();
    /* const { addOrRemoveItemToCompare, isInComparelist } = useComparelist(); */
    const { activeTab, setActiveTab, openNewReviewTab } = useProductTabs();
    const page_type = "product-detail";
    
    if(productGallery.value.length < 1){
      productGallery_palceholder.value = [ 
      	{ 
      		"mobile": { "url": magentoBaseUrl+"/media/catalog/product/placeholder/default/big_image.jpg"},
      		"desktop": { "url": magentoBaseUrl+"/media/catalog/product/placeholder/default/big_image.jpg" }, 
      		"big": { "url": magentoBaseUrl+"/media/catalog/product/placeholder/default/big_image.jpg" }, 
      		"alt": getProductName(product.value), 
      		"placeholder": "", 
      		"videocontant": null 
      	} 
      ];
    }


    /* const klaviyoViewProductTracker = () => {
      if (isAuthenticated){
        var _learnq = _learnq || [];
        var item = {
          "ProductName": product?.value?.name ?? '',
          "ProductID": product?.value?.id ?? '',
          "SKU": product?.value?.sku ?? '',
          "Categories": product?.value?.categories ?? [],
          "ImageURL": product?.value?.image?.url ?? '',
          "URL": "https://" + product?.value?.url_key + ".html",
          "Brand": product?.value?.manufacturer_name ?? '',
          "Price": product?.value?.price_range?.maximum_price?.final_price?.value ?? '',
        };
        _learnq.push(["track", "Viewed Product", item]);
      }
    }; */


    onMounted(() => {

      send_kla();

      /* klaviyoViewProductTracker(); */
      if (process.client) {
        // @ts-ignore
        if (Extend) {
          setTimeout(function () {
            // @ts-ignore
            Extend.buttons.render('#extend-offer', {
              referenceId: product.value.sku, 
              storeId: 'cb9140a0-e99c-4755-ae3f-1a09b470b8ea',
              region: 'US',
              locale: 'en_US'
            })
          }, 1000);
        }
      }      
      setTimeout(function(){
        window.dispatchEvent(new Event('resize'));
      },1000);
    });

    const productShortDescription = computed(() => props.product?.short_description?.html || '',);
    const productPrice = computed(() => getProductPrice(props.product).regular);
    const productSpecialPrice = computed(() => getProductPrice(props.product).special);
    const totalReviews = computed(() => getTotalReviews(props.product));
    const averageRating = computed(() => getAverageRating(props.product));
    const addToCartError = computed(() => cartError.value?.addItem?.message);
    const userEmail = computed(() => userGetters.getEmailAddress(user.value));
    const userFirstName = computed(() => userGetters.getFirstName(user.value));
    const userLastName = computed(() => userGetters.getLastName(user.value));

    const send_kla = () => {
      if (process.client) {
        //@ts-ignore
        var klaviyo = window.klaviyo || [];
        var item = {
          "ProductName": computed(() => getProductName(props.product)).value,
          "ProductID": computed(() => getProductId(props.product)).value,
          "SKU": computed(() => getProductSku(props.product)).value,
          "Categories": computed(() => getProductCategory(props.product, '')),
          "ImageURL": getProductThumbnailImage(props.product),
          "URL": computed(() => getProductSlug(props.product)).value,
          "Brand": "",
          "Price": computed(() => getProductPrice(props.product)).value.final
        };
        klaviyo.push(['track', 'Viewed Product', item]);
      
        var _learnq = window._learnq || [];
        var item = {
          "ProductName": computed(() => getProductName(props.product)).value,
          "ProductID": computed(() => getProductId(props.product)).value,
          "SKU": computed(() => getProductSku(props.product)).value,
          "Categories": computed(() => getProductCategory(props.product, '')),
          "ImageURL": getProductThumbnailImage(props.product),
          "URL": computed(() => getProductSlug(props.product)).value,
          "Brand": "",
          "Price": computed(() => getProductPrice(props.product)).value.final
        };
        _learnq.push(["track", "Viewed Product", item]);
      }
    };

    const askus = () => {
      activeTab.value = 1;
    };

    const addToCart = (product, qty) => {
      if (process.client) {
        // @ts-ignore
        const component = Extend.buttons.instance('#extend-offer')
        const plan = component.getPlanSelection()
        const product_item = component.getActiveProduct()
        if (plan) {
          plan['product'] = product_item.id;
          product['warranty'] = plan;
          addItem({ product, quantity: parseInt(qty) })
        } else {
          const product_sku =  product.sku;
          // @ts-ignore
          Extend.modal.open({
            referenceId: product_sku,
            onClose: function(plan, product_item) {
              if (plan && product_item) {
                plan['product'] = product_item.id;
                product['warranty'] = plan;
                addItem({ product, quantity: parseInt(qty) });
              } else {
                addItem({ product, quantity: parseInt(qty) })
              }
            },
          })
        }
      }
      //addItem({ product, quantity: parseInt(qty) })
    };

    const savingprice = (reg_p, spec_p) => {
      return reg_p - spec_p;
    };

    if(productGallery.value.length < 1){
      productGallery_palceholder.value = [ 
        { 
          "mobile": { "url": magentoBaseUrl+"/media/catalog/product/placeholder/default/big_image.jpg" }, 
          "desktop": { "url": magentoBaseUrl+"/media/catalog/product/placeholder/default/big_image.jpg" }, 
          "big": { "url": magentoBaseUrl+"/media/catalog/product/placeholder/default/big_image.jpg" }, 
          "alt": getProductName(product.value),
          "placeholder": "", 
          "videocontant": null 
        } 
      ];
      //var productGallery_palceholder.value = Object.keys(productGallery_palceholder.value).map((key) => [key, productGallery_palceholder.value[key]]);
    }

    const string_to_json = (message) => {
      const obj = JSON.parse(message);
      return obj;
    };
    
    return {
      askus,
      addToCart,
      addItem,
      addItemToWishlist: addOrRemoveItem,
      /* addItemToComparelist: addOrRemoveItemToCompare, */
      averageRating,
      totalReviews,
      canAddToCart,
      isAuthenticated,
      isInWishlist: computed(() => isInWishlist({ product: props.product })),
      /* isInComparelist: computed(() => isInComparelist({ product: props.product })), */
      isCartLoading,
      productShortDescription,
      productGallery,
      productGallery_palceholder,
      getProductName,
      productPrice,
      productSpecialPrice,
      qty,
      imageSizes,
      setActiveTab,
      openNewReviewTab,
      activeTab,
      TabsConfig,
      addToCartError,
      userEmail,
      savingprice,
      addedtocart_id,
      page_type,
      string_to_json
      /* send_kla, */
    };
  },
});
