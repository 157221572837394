import { ref } from '@nuxtjs/composition-api';
import priceNotifyGql from './query/priceNotify.gql';
import { useApi } from '~/composables/useApi';

type priceNotifyResponse = {
  customer_email: '',
};
export function useNotify() {
  const { query } = useApi();
  const usePriceNotify = async (queryVariables) => {
    const priceNotify = ref<any | null>(null);
    await query<priceNotifyResponse>(priceNotifyGql, queryVariables)
      .then((response) => {
        priceNotify.value = response?.data;
      })
      .catch((error) => {

      }).finally(() => {
        
      });;
    return {
      priceNotify,
    };
  };
  return { usePriceNotify }
};

export default useNotify;