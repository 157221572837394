





















import { useUser } from '~/modules/customer/composables/useUser';
import {
  ref,
  computed,
  defineComponent,
  PropType,
  toRef,
  useContext,
} from '@nuxtjs/composition-api';
import { Product } from '~/modules/catalog/product/types';
import userGetters from '~/modules/customer/getters/userGetters';
import useStockNotifyqry from '~/modules/czar/productalert/stockNotify'
import { useUiNotification } from '~/composables';

export default defineComponent({
    name: 'StockAlert',
    props: {
        product: {
        type: [Object, null] as PropType<Product>,
        default: null,
        },
        isFetching: {
        type: Boolean,
        default: true,
        },
    },
    setup(props) {
        /* const { isAuthenticated, user, register, login, loading, load: loadUser } = useUser();
        const userEmail = computed(() => userGetters.getEmailAddress(user.value)); */
        const { app: { i18n } } = useContext();
        const { isAuthenticated } = useUser();
        const product = toRef(props, 'product');
        const { useStockNotify } = useStockNotifyqry();
        const notify_stock_pop = ref('no');
        const userEmailGuest = ref('');
        const error_msg = ref('');
        const mp_customer_email = ref('');
        const { send: sendNotification } = useUiNotification();

        const NotifyPopupStock = async () => {
          if(typeof window !== 'undefined') {
            mp_customer_email.value = localStorage.getItem('mp_customer_email');
            if (isAuthenticated.value && mp_customer_email) {
              var queryVariables = {
                email: mp_customer_email.value,
                productSku: product.value.sku
              }
              await useStockNotify(queryVariables).then(response => {
              if (response.stockNotify.value?.MpProductAlertNotifyInStock != null) {
                var message = "Thanks! We'll send you an email once this product is back in stock.";
                var type = 'success';
                var icon = 'check';
                stockNotification(message, type, icon);
              } else {
                var message = "You have already subscribed this product.";
                var type = 'danger';
                var icon = 'cross';
                stockNotification(message, type, icon);
              }
            }).catch((error) => {
              console.log(error);
            }).finally(() => {
              /* priceNotification(); */
            });
              notify_stock_pop.value = 'no';
            } else {
              notify_stock_pop.value = 'yes';
            };
          };

            /* if (isAuthenticated.value) {
                var queryVariables = {
                email: userEmail.value,
                productSku: product.value.sku
                }
                const useStockNotifyResult = useStockNotify(queryVariables);
                notify_stock_pop.value = 'no';
            } else {
                notify_stock_pop.value = 'yes';
            }; */
        }

        const notify_me_stock_guest = async () => {
            if (userEmailGuest.value) {
                error_msg.value = '';
                var queryVariables = {
                email: userEmailGuest.value,
                productSku: props.product.sku
                };
                await useStockNotify(queryVariables).then(response => {
                  if (response.stockNotify.value?.MpProductAlertNotifyInStock != null) {
                    var message = "Thanks! We'll send you an email once this product is back in stock.";
                    var type = 'success';
                    var icon = 'check';
                    stockNotification(message, type, icon);
                  } else {
                    var message = "You have already subscribed this product.";
                    var type = 'danger';
                    var icon = 'cross';
                    stockNotification(message, type, icon);
                  }
                }).catch((error) => {
                  console.log(error);
                }).finally(() => {
                  /* priceNotification(); */
                });
                notify_stock_pop.value = 'no';
                userEmailGuest.value = '';
            } else {
                error_msg.value = '*This is a required field.';
            }
        }

        const stockNotification = (message, type, icon) => {
          sendNotification({
            id: Symbol('user_updated'),
            message: i18n.t(message) as string,
            type: type,
            icon: icon,
            persist: false,
          });
        };

        const close_popup_modal = async () => {
          notify_stock_pop.value = 'no';
        };

        return {
            NotifyPopupStock,
            /* userEmail, */
            notify_stock_pop,
            close_popup_modal,
            notify_me_stock_guest,
            userEmailGuest,
            error_msg,
        }
    }
});
